.form_entry{
    padding-bottom: 25px;
    text-align: start;
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0.01px;
    position: relative;
    font-family: myRaleway;

    .field_title{
        font-weight: normal !important;
        label{
            font-size: 12px;
            font-weight: bold;
            padding-right: 7px;
            font-style: normal;
            color: #333e6b;
            padding-top: 0px;
            margin: 0;
            font-family: myYantramanav;
        }
    }

    input[type="time"]::-webkit-clear-button {
        display: none;
    }

    input[type="time"]::-ms-clear {
        display: none;
    }

    input[type="time"]::-webkit-inner-spin-button, 
    input[type="time"]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: none;
        margin: 0; 
        padding: 0;
    }

    ::placeholder {
        color: #333e6b;
    }

    

    .input_field{
        border-radius: 0.08cm;
        font-size: 17px; 
        box-sizing: content-box;
        width: 161px;
        height: 39px;
        text-align: left;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 0; 
        border-color: #d9d9d9;
        color: #333e6b;
        border-style: solid;
        border-width: 1.4px;
        font-weight: 550;
        font-family: Arial;
        z-index: 5;

    }

    .input_field::placeholder {
        font-size: 16px !important;
        color: #BABABA;
        font-weight: normal !important;
        font-family: myYantramanav;
    }
    
    .error_input_field{
        border-color: #A11324 !important;
    }

    .input_background{
        position: absolute;
        font-size: 16px;
        color: #BABABA;
        text-align: right;
        transform: translateY(10px);
        font-style: normal;
        box-sizing: content-box;
        width: 170px;
        pointer-events: none;
        z-index: 0;
    }

    .error_msg{
        position: absolute;
        color: #A11324;
        font-family: myRaleway;
        font-style: italic;
        font-size: 12px;
        opacity: 0;
        pointer-events: none !important;
    }
    .active{
        opacity: 1;
    }
    
    .tooltip-properties{
        position: absolute;
        z-index: 10;
        transform: translateX(200px) translateY(30px) !important;
    }

    .rc-highlight {
        background-color: #00ff4c !important;
        border: 3px;
        border-radius: 6px;
        opacity: 0.4;
        z-index: 0;

      }
  
      .container_timetag{
        position: absolute;
        transform: translateY(-32px) translateX(1px);
        z-index: 0;
        
        width: 51px;
        height: 31px;
        pointer-events: none;
        div{
            z-index: 0;
            width: 51px;
            height: 31px;
            transition: all 0.4s ease-in-out !important;
            color: #ffffff00;
        }
      }
    .extra-info {
        font-family: myRaleway;
        font-style: italic;
        font-size: 10px;
        color: #333e6b;
        text-align: left;
        font-weight: 500;
        padding-left: 5px;
    }

}