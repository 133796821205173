.timetag{
  height: 0px;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  overflow: visible;
  color: #333d6b !important;
  

  .timetag-line{
    display: block;
    height: 1px;
    border: 0;  
    border-top: 1px solid #000000;
    padding: 0;
    width: 300px;
    position: relative;
    z-index: 1;
    top: -0.5px;
    
  }
  
  .hour-position{
    position: absolute;
    transform: translateY(-13px) translateX(-110px);
    width: 80px;
    display: flex;
    flex-direction: row-reverse;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 8px 8px #fff;
    margin-left: 19px;

    .rc-highlight {
      background-color: #00ff4c !important;
      border: 3px;
      border-radius: 6px;
      opacity: 0.5;

    }

    .container_timetag{
      div{
        transition: all 0.4s ease-in-out !important;
      }

      
    }
    
  }
  .hour-font{
    font-family: myYantramanav;
    font-weight: normal !important;
    font-size: 16px;
    text-align: right;
    color: #333e6b !important;
  
  }

  
}
