.passingtime{
    
    padding: 0px;
    padding-left: 20px;
    overflow: hidden;
    position: relative;
    
    .passingtime-box {
        float: left;
        width: 280px;
        height: 30px;
      }
      
      .passingtime-color {
        background: #e4e5eb;
      }

      .passingtime-column{
        padding: 0 0 0 0 ;
        margin: 0 0 0 0 ;
       
      }
      .passingtime-column-text-duration{
        position: absolute;
        left: 55%;
        font-style: italic !important;
        padding-top:3px;
        font-family: myRaleway;
        font-size: medium;
      
      }
      .passingtime-column-text-passingtime{
        width: 100%;
        height: 100%;
        font-family: myRaleway;
        font-weight:normal !important;
        font-style: italic !important;
        font-size: 12px;
        padding: 5px 0 0 10px;
        text-align: left;
        position: relative;
        
      }
      .passingtime-row{
        width: inherit;
        height: inherit;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
      }

}
