.daytypesettings{
    
    text-align: left;

    
    

    .settings_header{
        width: 424px;
        padding: 20px 25px 20px 25px;

        background-color: #F1F1F1;
        

        display: flex;
        justify-items: center;
        align-items: center;

        .arrow_img{
            padding-left: 65px;
            display: none;
            pointer-events: none;
        }

        .settings_text{
            font-weight: bold;
            font-size: 18px;
        }
        .btn_container{
            transform: translateX(-25px);
            position: absolute;
            width: 424px;
            height: 66px;
            .arrow_btn{

                width: 100%;
                height: 100%;
            }
        }
        
    }

    .displayed{
        display: inherit !important;
    }

    .settings{
        padding: 20px 25px 20px 25px;

        background-color: #F1F1F1;
        display: none;

        .form_entry{
            padding-bottom: 35px !important;
        }
        .delete{
            margin-right: 35px !important;
            width: 200px;
            font-style: normal;
            font-family: myYantramanav;
            font-size: 18px !important;
            margin-top: 15px;
            padding-right: 5px !important;
            .img{
                padding-right: 15px;
                transform: translateY(-2px);
            }
        }

        .disabled{
            text-decoration: none !important;
            color: #D0D3D4 !important;
            border-color: #D0D3D4 !important;
            pointer-events: none !important;
            .img{
                filter: opacity(0.3);
            }
        }
    
        .save{
            margin-left: 35px !important;
            width: 100px;
            font-style: normal;
            font-family: myYantramanav;
            font-size: 18px !important;
            margin-top: 15px;
        }

        .saved{
            background-color: #45BA00 !important;
        }
    
        .info_text{
            transform: translateY(-20px);
            font-size: 12px;
            padding-bottom: 10px;
        }
    }

    
}


@media only screen and (max-width: 1082px) {
    .daytypesettings{
        .settings_header{
            width: 93vw;
            .btn_container{
                width: 93vw;
            }

            .arrow_img{
                padding-left: 82vw;
                transform: translateY(-15px);
            }
        }
        .settings{
            width: 93vw;
        }
    }
}