.day_type_editor{
    position: relative;
    width: 94vw;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    .editor_container{
        position: relative;
        padding-left: 25px;
        padding-right: 0px;
        padding-top: 115px;
        width: 100%;
        max-width: 1100px;

        

        .editor_col{
            display: flex;
            flex-grow: 3;
        }

        .day_editor{
            padding-top: 35px;
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
        }

        .day_type_settings_container{
            position: relative;
            display: flex;
            flex-grow: 1;
            flex-direction: row-reverse;
            overflow: hidden !important;
            z-index: 20;
        }

        .active{
            opacity: 1 !important;
            pointer-events: all !important;
        }

        .info{
            padding-right: 120px;
            text-align: left;
            font-style: italic;
            font-size: 14px;
            font-weight: normal;
            font-family: myRaleway;
            position: relative;
            margin: 0;
            color: #333E6B;
        }
    }

    .button_clickable_text_b{
        font-size: 16px;
    }
    
}


@media only screen and (max-width: 1082px) {
    .day_type_editor{
        .editor_container{
            .day_type_settings_container{
                position: absolute;
            }
            .editor_col{
                padding-top: 105px;
            }
        }
    }
}