.dailyviewheader{
    position: relative;
    width: 95.8vw;
    flex-grow: 1;
    
  .dailyviewtitle{
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      margin: 10% 0 2% 0;
  }
  .daytype{
    position: relative;
    color: #5BA9C7;
    font-weight: bold;
    top: 30%;
   
  }
  .instructionalminutes{
      font-weight: bold !important;
      font-size: 20px;
      
  }
  .edit_button{
        border-style: none none none none;
        color: #5DACC9;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        font-weight: bold;    
        top: 30% !important;
        
        .img{
            padding-right: 2px;
            padding-left: 5px;
        }
    }
    
    
    .editbtn-properties{     
        position: relative; 
        top: 30% !important;         
    }

    .header-box{
        max-height: 74px;
        position: relative;
        background-color: #F1F1F1;
        box-shadow: 0px 2px 4px #ddd;
        padding: 10px;
        text-align: center;
        display: flex;
        flex-direction: row;
         
    }
    .header-child{
        position: relative;
        height: 90%;
        max-height: 90%;
        margin-left: 1.4379%;
        margin-right: 1.4379%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .row-width{  
        position: relative;
        width: 100%;
    }
    .separator{
        border-left: 2px solid #DDDDDE;
        height: 90%;
        position: absolute;
        margin-left: 100%;
        top: 5px;
    }
    
   
}
