.overviewheader{
    position: fixed;
    transform: translateY(-100px) translateX(-0.9vw);
    width: 95.8vw;
    margin: 0;
    padding: 0;
    z-index: 59;
  .daytype{
    position: absolute;
    color: #5BA9C7;
    font-weight: bold;  
  }
  .font-properties{
      width: 100%;
      font-weight: bold !important;
      font-size: 20px;
      font-family: myYantramanav;      
  }
  
    .header-box{
        
        background-color: #F1F1F1;
        box-shadow: 0px 2px 4px #ddd;
        padding: 10px;
        text-align: center;
        
    }
    .header-child{
        position: relative; 
        height: 100%;
        padding: 0 !important;
    }
    .separator{
        border-left: 2px solid #DDDDDE;
        height: 100%;
        position: absolute;
        margin-left: 100%;
        top: 0%;   
    }
    .instructionalyeartooltip{
        z-index: 10;
        top: 0%;
        left: 93%;
        padding: 0 0 0 0;
        margin:0 0 0 0;
        

    }

    

}
