.lunchtimeblocksselector{
   
    
    padding-left: 25px;
    padding-bottom: 25px;
    .title{
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        padding-bottom: 5px;
        font-family: myYantramanav;
    }
    .lunch_position_selectors{
        max-width: 324px;
    }
}
