.selectedperiodtomodify{
    .selected_period_delete_btn{
        position: absolute;
        transform: translateX(80px) translateY(7px);
    }

    .box{
        width: 100px;
        height: 30px;
        margin: 5px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
    }
    
}


