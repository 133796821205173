.periodselector{
    .periods_display{
        position: absolute;
        display: block;
        z-index: 5001;
        width: 210px;
        background-color: #fff !important;
        border-radius: 5px;
        padding-top: 5px;
        margin-top: 3px;
        opacity: 0;
        pointer-events: none;
        left: 165px;
        font-family: myYantramanav;
        max-height: 145px;
        overflow-y: auto;

        .period{
            text-align: left;
            width: 100%;
            padding-left: 15px;
        }

        .period:hover{
            background-color: #EEE;
        }

        .button_clickable_text_b{
            padding-left: 15px;
            font-weight: normal;
            width: 100%;
            justify-content: left;
            height: fit-content !important;
            margin: 0 !important;
        }
        .button_clickable_text_b:hover{
            background-color: #EEE;
        }


        hr {
            display: block;
            height: 1px;
            width: 100%;
            border: 0;
            border-top: 1px solid #F8F8F8;
            margin: 1em 0;
            padding: 0;
            margin: 0;
        }
    }

    
}
