.overviewpage{
    margin-top: 100px;
   
    .dailyviewscroll-container {
        margin-top: 10%;
        margin-left: 1.2%;
        margin-right: 1.2%;
        display: flex;        
        overflow-x: scroll;
        overflow-y: hidden;      

    }
    .dailyviewscroll-element{
        flex-grow: 1;
        margin-left: 100px;
        padding-top: 15px;
        padding-bottom: 30px;
                
    }
    .dailyviewscroll-element + .dailyviewscroll-element {
        margin-left: 10%;
    }

    .dailyweekly-title{
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin: 2% 0 1% 0%;
        
    }   
    .element-margin{
        margin: 1% 0 0 0;
        
    }
    .dailyviewheader{
        position: relative;
        left: 0;
      }
    
}
