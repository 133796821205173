.freeingminuteswindow{
    font-family: myYantramanav;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    padding-top: 10vw;

    .freeing_periods_selector{
        opacity: 0;
        pointer-events: none;
    }
    .overlay {
        position: fixed;
        z-index:5200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        opacity: 1;
        transition: 200ms ease-in-out;
    }
    .window{
        position: fixed;
        background-color: #fff;
        z-index: 5300;
        padding: 25px 3%;
        border-radius: 15px;
        transform: translateX(15vw);
        width: 70vw;
    }

    .title{
        font-size: 24px;
        color: #333E6B;
        font-weight: bold;
    }

    .subtitle{
        color: #5BAAC8;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 35px;
        padding-left: 15px;
        transform: translateX(-10px);
    }

    .send_btn{
        min-width: 100px;
        max-width: 100px;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .cancel_btn{
        font-weight: bold;
        margin-right: 10px;
    }

    .donotshowagain{
        padding: 0;
        margin: 0;
        align-items: center;
        .text{
            display: grid;
            font-size: 14px;
            align-content: center;
            padding: 0;
            margin: 0;
            padding-left: 10px;
        }
        
    }

    .radios{
        margin: 0;
        padding: 0px 7.5%;
        text-align: left;
        
        

        .radio_text{
            font-weight: bold !important;
        }

        .radio_secondary_text{
            font-weight: lighter !important;
            font-size: 13px;
            font-style: italic;
        }

        .dropdown_form_entry{
            width: fit-content;
            .input_background{
                text-align: left;
                padding-left: 15px;
                font-size: 18px;
            }
            
        }
    }
}
