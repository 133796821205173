.availableminutescard{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    padding-left: 10px;
    margin: 0;
    font-weight: bold;
    width: fit-content;

    .minmax{
        padding: 0;
        margin: 0;
        font-size: 12px;
        color: #333E6B;
        font-weight: bold !important;

    }
    
    .title{
        font-size: 12px;
        padding: 0;
        margin: 0;
        font-weight: normal !important;
        transform: translateY(-3px);
        color: #333E6B;
    }
    .actual_minutes{
        font-size: 20px;
        font-family: myYantramanav;
        font-weight: bold;
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

    .help{
        font-weight: normal !important;
        font-style: italic !important;
        font-size: 11px;
        padding-left: 8px;
        transform: translateY(10px);
    }

    .limit{
        font-size: 14px;
        padding: 0;
        margin: 0;
        font-family: myYantramanav;
        font-weight: bold;
        color: #333E6B;

    }

    .left_minutes{
        font-size: 14px;
        padding: 0;
        margin: 0;
        

        
    }

    .good{
        color: #5ea809;
    }

    .bad{
        color: #a11324;
    }

    .instructionalyeartooltip{
        position: absolute;
        top: -2%;
        left: 108%;
        padding: 0 0 0 0;
        margin:0 0 0 0;
    }


}
