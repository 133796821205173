.weekssettingscomponent{
    padding-bottom: 30px;
    .title{
        font-size: 11px;
        font-style: normal;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .button_clickable_text_b{
        padding-left: 0 !important;
        font-size: 14px;
    }
}
