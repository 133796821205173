.active{
    opacity: 1 !important;
    pointer-events: all !important;
}

.createnewschedulewindow{
    font-family: myRaleway;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    padding-top: 35px;
    font-size:14px;
    .overlay {
        position: fixed;
        z-index:5200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
        opacity: 1;
        transition: 200ms ease-in-out;
    }
    .window{
        position: fixed;
        background-color: #fff;
        z-index: 5300;
        padding: 25px 10%;
        border-radius: 15px;
        transform: translateX(50vw) translateX(- 50%) translateY(50%);
        max-width: 900px;
        
    }

    .title{
        font-size: 24px;
        color: #333e6b;
        font-weight: bold;
       
    }

    .subtitle{
        color: #5BAAC8;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 35px;

        
    }

    .buttons_container{
        position: relative;
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;
        width: 100%;
        transform: translateY(30%);
        height: 80px;
        
        
    }
  
    .button_clickable_text_a{
        font-size: 18px;
        color: #9B9B9B;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .button_clickable_text_a{
        font-weight: bold;
        transform: translateY(-5px);
        line-height: normal;
    }

    .button_type_a{
        background-color: #5BAAC8;
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        border-width: 1px;
        border-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
  

    .back_btn + .next_btn{
        margin-left: 30px;
    }

    .exportbtn-properties{
        display: flex;
        align-items: center;
        padding:0 0 0 0;
        margin: 0 0 0 0;
        height: 55px !important;   
        width: 260px;  
        line-height: normal; 
    }

    .createschedulebtn-properties{
        display: flex;
        align-items: center;
        padding:0 0 0 0;
        margin: 0 0 0 0;
        height: 55px !important; 
        width: 250px;  
        line-height: normal;   
    }
    
    .functionalbutton{
        width: fit-content;
        font-size: 16px;
        .img{
            padding-right: 2px;
            filter: invert(40%) sepia(34%) saturate(5031%) hue-rotate(175deg) brightness(96%) contrast(61%);     
        }
        
        
    }
    
    .row{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
}