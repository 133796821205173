.day_types_form{

    padding-bottom: 10px;

    .clean-space{
        height: 0px;
    }
    .form_entry{
        .input_field{
            width: 100px;

            
        }
        .input_background{
            width: 110px;
        }
    }

    .day_periods_fields{
        display: inline-block;
        transform: translateX(50%) translateX(-62px);
        padding-bottom: 10px;

        .form_entry{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .dropdown_form_entry{

        .label{
            .tooltip-properties-dropdown{
                position: absolute ;
                z-index: 10 ;
                transform: translateX(204px) translateY(10px) !important;
            }
        }
    }
 
    .form_entry{

        .tooltip-properties{
            position: absolute ;
            z-index: 10 ;
            transform: translateX(170px) translateY(29px) !important;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            color: #5AA9C7;
            font-family: myYantramanav;
        }
        
    }
    
}