.schoolselector{
    padding-bottom: 25px;
    .school_list{
        display: none;
        width: 220px;
        max-height: 20vw;
       
        margin: 2px;
        margin-top: 0;
        overflow-y: scroll;

        .highlight{
            background-color: rgb(255, 255, 0) !important;
            padding: 0;
            margin: 0;
        }

        .no_schools_found{
            background-color: #C70B27;
            color: #fff;
            padding: 2px;
            font-size: 14px;
            padding-left: 13px;
            border: 1px transparent;
            border-radius: 5px;
        }

        .searching{
            background-color: rgb(233, 233, 233);
            padding: 2px;
            font-size: 14px;
            padding-left: 13px;

        }

        .school_button{
            background-color: rgb(233, 233, 233);
            padding: 2px;
            padding-left: 13px;

            .school_name{
                font-size: 14px;
            }

            .school_info{
                font-size: 12px;
                color: rgb(155, 155, 155);
            }
        }

        .school_button:hover{
            background-color: rgb(206, 206, 206);
        }
    }

    .displaying{
        display: inherit !important;
    }


    .form_entry{
        padding: 0;
        .input_field{
            width: 200px;

        }
    }

    text-align: left;
    .info_popup{
        .clickable_text{
            color: #75B7D0;
            font-size: 12px;
            width: 220px !important;
        }
    }
    

}
