.designerheader{
    z-index: 5061;
    position: fixed;
    background-color: #333E6B;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    color: #fff;

    .row{
        margin: 0px !important;
    }

    .main_row{
        width: 100%;
        position: relative;

        .column{
            display: flex;
            align-items: center;
        }
    }
    
    .designer_title_container{
    
        height: 32px;
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding-right: 15px;
        padding-left: 35px;
        .designer_title{
            letter-spacing: 1px;
            color: #fff;
            font-weight: bold;
            font-size: 20px;
            height: auto;
        }
    }
    
    .buttons_container{
        .row{

            .column{
                padding-right: 15px;
                padding-left: 15px;
            }
        }

        .export_button{
            outline: none;
            font-size: 12px;
            width: 150px;
            height: 40px;
            background-color: #5aa9c7;
            .img{
                filter: brightness(4);
            }     
        }
        .export_button:hover{
            cursor: pointer;
            background-color:#2e8eb3 !important;
        }

    }
    
    .template_name{
        letter-spacing: 1px;
        color: #DADCE4;
        font-size: 20px;
        height: auto;
        padding-left: 12px;
        display: flex;
        flex-grow: 2;
    }

    .linker_text{
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
        color: #5AA9C7;
        font-size: 16px;
        font-family: 2;
        font-weight: bold;
        
    }

    .linker_text:hover{
        cursor: pointer;
        color:#2e8eb3 !important;
    }
    
}

@media only screen and (max-width: 883px) {
    .designerheader{
        .buttons_container{
            flex-direction: row !important;
            padding-left: 20px;
        }

        .export_button{
            height: 30px !important;
            
            
        }
    }
}