.info_popup{
    .popup{
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%) scale(0);
        border: 1px solid  #d9d9d9;
        border-radius: 10px;
        z-index:10;
        background-color: white;
        width: 300px;
        max-width: 50%;
        transition: 200ms ease-in-out;

        .header{
            padding: 5px 15px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 40px;
            font-size: 15px;
            display: flex;
            font-weight: bold;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid  #d9d9d9;
        }

        .info{
            padding: 15px 15px;
            font-size: 14px;
            font-weight: normal !important;
            text-align: left;
            font-style: italic;
        }

        .close_btn{
            transform: translateY(3px);
            cursor: pointer;
            border: none;
            outline: none;
            background: none;
            font-size: 1.75rem;
            font-weight: bold;
            color: #d9d9d9;
        }
    }

    .popup.active{
        transform: translate(-50%,-50%) scale(1);
    }
    
    .clickable_text{
        color: #75B7D0;
        font-size: 13px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .clickable_text:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    #overlay {
        position: fixed;
        z-index:9;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
        opacity: 0;
        transition: 200ms ease-in-out;
    }

    #overlay.active{
        pointer-events: all;
        opacity: 1;
    }
}