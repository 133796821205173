.dropdown_form_entry{
    text-align: start;
    justify-content: center;
    font-weight: 300;
    padding-bottom: 0;
    margin-bottom: 0;

    .field_title{
        font-weight: bold !important;
        text-align: left;

        label{
            font-size: 12px;
            font-weight: bold;
            padding-right: 7px;
            font-style: normal;
            color: #333d6b;
            padding-top: 0px;
            margin: 0;
            font-family: myYantramanav;
        }
    }

    .label{
        position: relative;
        font-size: 12px;
        font-weight: bold;
        padding-right: 7px;
        font-style: normal;
        color: #333d6b;
        padding-bottom: 2px;
        margin: 0;
        font-family: myYantramanav;

        .input_field{
            border-radius: 0.08cm;
            font-size: 17px; 
            box-sizing: content-box;
            width: 220px !important;
            max-width: 210px;
            height: 40px;
            padding-bottom: 15px;
            text-align: left;
            background-color: #fff;
            border-color: #d9d9d9;
            color: #333d6b;
            border-style: solid;
            border-width: 1.4px;
            font-weight: 550;
            font-family: Arial;
            padding-bottom: 0;
            z-index: 2;
            padding-right: 10px;
            
        }

        .arrow_bg{
            position: absolute;
            background-color: #fff;
            z-index: 0;
            height: 36px;
            width: 30px;
            background-color: #fff;
            transform: translateY(3px) translateX(190px);
            pointer-events: none;
        }
    }

    .label:after{
        position: absolute;
        content: "";
        transform: translateX(-25px) translateY(18px);
        border: 6px solid transparent;
        border-color: #75B7D0 transparent transparent transparent;
        z-index: 2;
        pointer-events: none;


    }


    

    @mixin x-appearance ($value){
        -webkit-appearance: $value;
           -moz-appearance: $value;
                appearance: $value;
    }

    select {
        @include x-appearance(none);
        text-indent: 10px;
        text-overflow: '';
    }

    .input_background{
        position: absolute;
        font-size: 16px;
        color: #BABABA;
        text-align: right;
        transform: translateY(-34px);
        font-style: normal;
        box-sizing: content-box;
        width: 150px;
        pointer-events: none;
        z-index: 0;
        font-weight: normal !important;

    }

    .empty_text{
        position: absolute;
        font-size: 16px;
        color: #BABABA;
        text-align: left;
        transform: translateY(-32px);
        font-style: normal;
        box-sizing: content-box;
        width: 150px;
        pointer-events: none;
        z-index: 0;
        padding-left: 12px;
        font-weight: normal !important;

    }

    .tooltip-properties-dropdown{
        position: absolute;
        z-index: 10;
        transform: translateX(205px) translateY(10px) !important;
    }


}

