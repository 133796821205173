.weeklyviewstack{
    position: relative;
    padding: 5px;
    overflow: hidden;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: auto;
    height: auto;
    


    .weekday-spacing{
        margin-bottom: 10px;
    }

    .daytypename{
        margin-bottom: 10px;
        font-weight: bold;
    }

    .block{  
        display: flex;
        margin-bottom: 2px;  
        margin-right: 2px;    
        font-size: 14px;
        font-weight: bold;
        padding-left: 10px;
        padding-top: 2px;
        justify-content: left;
        align-items: top;
        font-family: myYantramanav;

    }
    
    .period{  
        max-width: 183px;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center; 
        align-items: top;
        top: 5px;
        background-color: #CBCFE3;
        margin-bottom: 2px;  
        margin-right: 1px;    
        font-size: 14px;
        flex-grow: 1;
        font-weight: bold;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: left;
    }

    .lunchbox{
        position: relative;
        margin-right: 3px;
        margin-bottom: 1px;
        background-color: #fff !important;
        width: 100%;
        justify-content: left;
         
        .insideperiod-properties{
            margin-bottom: 2px ;  
            padding-left: 5%;  
            padding-right: 5%;           
            justify-content: left;
        }
    }
    
    .lunch{
        width: 100%;
        color: #333d6b;
        height: 70px;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: top;
        background-color: #CBCFE3;
        margin-bottom: 1px;
        font-size: 14px;
        padding-left: 5%;
        padding-right: 5%;
        background-color: #e4e5eb;
        justify-content: left;
        
    }

    .insideperiod-properties{
        min-height: 70px;    
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: top;  
        
    }

    
}
