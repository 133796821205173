.TemplateCard {
  margin: 60px;
  padding: 0;
  .TemplateCard-text {
    font-size: calc(24px + 1vmin);
    margin: 0;
  }

  .component-padding{
    padding: 50px;
    margin: 0 0 0 0;
    
    width: 60px;
    height: 60px;
   
  }
  .padding-image{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
   
  }
  .linkerbtn-properties{
    color: #75B7D0;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  
  }
  .generalbtn-properties{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    align-items: center;
  }
}
