.generalbtn {
  border-radius: 10px;
  background: #5AA9C7;
  
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0px;
  font-size: 15px;
  font-weight: bold;

}
