.pdfview {
   
    .dailyviewscroll-container {
        margin-left: 1%;
        margin-right: 1%;
        display: flex;        
        overflow-x: hidden;
        overflow-y: hidden;      

    }
    .dailyviewscroll-element{
        flex-grow: 1;
        padding-top: 15px;
        padding-bottom: 30px;
                
    }
    .dailyviewscroll-element + .dailyviewscroll-element {
        margin-left: 10%;
    }

    .dailyweekly-title{
        max-width: 95.8vw;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin: 1.5% 0 1% 0%;
        
    }   
    .element-margin{
        margin: 1% 0 0 0;
        
    }
    .dailyviewheader{
        position: relative;
        left: 0;
    }

    @media print {
        .page_break {
            page-break-after: always;
        }
    }

    .pdf_footer{
        width: 100%;
        height: 15vh;
        background-color: #333E6B;
        margin: 0 !important;
        padding: 0 !important;
        position: fixed;
        bottom: 0;
    
        .page{
          color: #fff;
          font-size: 1.55em !important;
          font-weight: bold !important;
          text-decoration: none;
        }
        .unlocking{
    
          border-right: 1px solid #fff;
          justify-content: center;
          width: 50% !important;
          height: 11vh;
          margin-top: 2vh;
    
          img{
            height: 6.5vh;
            width: auto;
            margin-bottom: 1vh;
            margin-top: 0vh;
          }
        }
    
        .abl{
          justify-content: center;
          width: 50% !important;
          margin-top: 2vh;
          height: 11vh;
          padding-left: 15%;
          padding-right: 15%;
    
          img{
            height: 4.5vh;
            width: auto;
            margin-bottom: 2vh;
            margin-top: 1vh;
          }
        }
    }

    .link-pointer{
      cursor: pointer;
    }
    
}
