.dailyviewschedule{
    padding: 5px;
    overflow: hidden;
    position: relative;
    margin-left: 0;
    flex-grow: 1;
    
    .dailyviewschedule-size{
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .dailyviewscroll-container {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      display: flex;        
      overflow-x: scroll;
      overflow-y: hidden;
      @media print {
        flex-wrap: wrap;
        overflow-x: hidden;
      }  
      
  }
  .dailyviewscroll-element{
      position: relative;
      flex-grow: 1;
      margin-left: 1%;
      padding-top: 0px;
      padding-bottom: 0px;
              
  }
  .dailyviewscroll-element + .dailyviewscroll-element {
      margin-left: 5%;
      
  }
   
}
