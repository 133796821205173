.availableminutesbox{
    display: flex;
    position: fixed !important;
    justify-content: center;
    margin: auto;
    width: 95.8vw;
    margin: 0;
    padding: 0;
    z-index: 5059;
    justify-content: left;

    .centeredbox{
        flex: 1;
        margin: 0;
        position: relative;
        height: auto;

        .separator{
            border-left: 1.5px solid #DDDDDE;
            height: auto;
            margin-bottom: 1%;
            margin-top: 1%;
            width: 2px;
            
         
        }
        
    }
 
    .editbuttoncontainer{
        display: flex;
        height: 100%;
        align-items: flex-end;
        margin: 0;
        padding-bottom: 10px;
        .button_clickable_text_b{
            margin: 0;
            padding: 0;
            font-size: 16px;

            .img{
                transform: translateY(-1px);
            }
        }
    }
    
}


.scrolling{
    
        .centeredbox{
            flex: 1;
            margin: 0;
            position: relative;

            .separator{
                border-left: 1.5px solid #DDDDDE;
                height: 75%;
                margin-top: 1%;
                width: 2px;
            }

            .availableminutescard{
        
                padding-top: 4px;
                padding-bottom: 0;
                
                .title{
                    font-size: 12px;
                    padding: 0;
                    margin: 0;
                }
                .actual_minutes{
                    font-size: 16px;
                    padding-top: 0px;
                    padding-bottom: 3px;
                    
                }
                .left_minutes{
                    display: none;
                    
                }
                .limit{
                    display: none;
                }
        
                .help{
                    transform: translateY(5px);
                }
            }
            
        }
        .editbuttoncontainer{
            display: flex;
            max-height: 45px;
            align-items: flex-end;
            padding-bottom: 10px;
            .button_clickable_text_b{
                margin: 0;
                padding: 0;
                font-size: 16px;

                .img{
                    transform: translateY(-1px);
                }
            }
        }
    
    
    
    
    
    
}

@media only screen and (max-width: 750px) {
    .availableminutesbox{

        .centeredbox{

            
        }
        .editbuttoncontainer{
            margin: 0;
            padding: 0;
            .button_clickable_text_b{
                transform: translateY(-10px);
                padding-left: 20px;

            }
        }
        
        
    }
    
    
    .scrolling{
        
            .centeredbox{

                height: 90px !important;
            }
            .editbuttoncontainer{

                .button_clickable_text_b{
                    padding-left: 20px;
                    transform: translateY(-15px);
    
                }
            }
        
        
        
        
        
        
    }
}