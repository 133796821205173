.timelimitsform{
    position: absolute;
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    min-height: auto;
    padding: 1.5%;

    .buttons_container{
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        width: 98%;
        justify-content: space-between;
        align-items: center;
        .row{
            display: flex;
            flex-direction: row-reverse;
            .button_type_a{
                width: 100px;
                height: 50px;
            }
            .button_clickable_text_a{
                width: 100px;
                height: 50px;
            }
        }
        .targetvaluestext{
            position: relative;
            color: #e3920c;
            font-weight: normal;
            font-family: myRaleway;
            font-style: italic;
            z-index: 5;
            font-size: 13px;
            left: 3%;  
            bottom: 100%; 
            width: fit-content;
            height: auto;
        }
    }
    .form_entry{
        padding-right: 25px;
    }



    .centeredbox_form{
        flex: 1;
        margin: 0;
        position: relative;
        height: auto;
        
       
        .separator{
            border-left: 1.5px solid #DDDDDE;
            height: auto;
            margin-bottom: 1%;
            margin-top: 0%;
            width: 2px;
            
         
        }
        
    }


    .instr_min_title{
        font-size: 12px;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        font-weight: bold;
        justify-content: center;
        width: 100%;
    }
    .actual_minutes{
        width: 100%;
        font-size: 20px;
        padding-top: 0px;
        padding-bottom: 5px;
        margin: 0 0 0 0;
        font-weight: bold;
        
        
    }

    .basicformfield-properties{
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: 10px;
        margin-right: -18px;
        font-size: 12px;
        padding: 0;
        font-weight: bold;
         
        .form_entry{
            
            .input_field{
                width: 55px;
                height: 30px;
                border-radius: 0.08cm;
                font-size: 12px; 
                box-sizing: content-box;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                border-color: #d9d9d9;
                color: #333d6b;
                border-style: solid;
                font-weight: normal;
                font-family: Arial;
                z-index: 5;
                border-width: 0px;
                text-align: center;
                
            }
            .error_input_field{
                border-color: #A11324 !important;
            }
            
            .input_background{
                position: absolute;
                font-size: 16px;
                color: #d9d9d9;
                text-align: right;
                transform: translateY(10px);
                font-style: normal;
                box-sizing: content-box;
                width: 170px;
                pointer-events: none;
                z-index: 0;
            }
        
            .empty_text{
                position: absolute;
                font-size: 16px;
                color: #d9d9d9;
                text-align: left;
                transform: translateY(10px);
                font-style: normal;
                box-sizing: content-box;
                width: 170px;
                pointer-events: none;
                z-index: 0;
                padding-left: 15px;
            }
        
            .error_msg{
                position: absolute;
                color: #A11324;
                font-family: myRaleway;
                font-style: italic;
                font-size: 12px;
                opacity: 0;
            }

            
            
            
        }

    }

    .thisnumberaffects_text{
        font-style: italic;
        font-weight: normal;
        font-family: myRaleway;
        font-size: 12px;

    }
    .normaltext{
        position: relative;
        top: 50%;
    }

    .column{
        position: grid;
        padding-left: 2.5%;
        padding-right: 2.5%;
        max-width: 305px;
    }



    .daysinstrschoolyear{
            position: relative;
            display: flex;
            justify-content: center;
            margin-left: 15px;
            margin-top: 5px;
            font-size: 12px;
            padding: 0;
            font-weight: bold;
                 
            .form_entry{
                margin: 0;
                padding-bottom: 0px;
                
                .input_field{
                    width: 185px;
                    height: 35px;
                    border-radius: 0.08cm;
                    font-size: 16px; 
                    box-sizing: content-box;
                    padding: 0 0 0 10px;
                    margin: 0 0 0 0;
                    border-color: #d9d9d9;
                    color: #333d6b;
                    border-style: solid;
                    font-weight: bold;
                    font-family: Arial;
                    z-index: 5;
                    border-width: 0px;
                    text-align: left;
                    margin-left: 0%;
                    
                }
                .input_background{
                    position: absolute;
                    font-size: 16px;
                    color: #d9d9d9;
                    text-align: right;
                    transform: translateY(7px);
                    font-style: normal;
                    box-sizing: content-box;
                    width: 170px;
                    pointer-events: none;
                    z-index: 0;
                }
                .error_msg{
                    position: relative;
                    color: #A11324;
                    font-family: myRaleway;
                    font-style: italic;
                    font-size: 12px;
                    opacity: 0;
                    left: 110%;
                    top: -50%;
                }            
            }
    }

    .disabled{
        text-decoration: none;
        background-color: #D0D3D4 !important;
        pointer-events: none;
        
    }

    .instructionalyeartooltipeditor{
        z-index: 1070;
        transform: translateY(-5px);
        padding: 0;
        margin: 1px 3px;
    }

   
    
}

.active{
    opacity: 1;
    pointer-events: all;
}
