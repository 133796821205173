.delete_daytype_modal{
  font-family: myYantramanav;
  opacity: 0;
  pointer-events: none;
  position: absolute;

  .window{
      position: fixed;
      background-color: #fff;
      z-index: 5300;
      width: 60vw;
      border-radius: 15px;
      transform: translateX(20vw) translateY(35vh);
      justify-content: center !important;

      .title{
          padding-bottom: 20px;
          font-size: 24px;
          color: #333E6B;
          font-weight: bold;
      }
      
      .centered_form{
          display: inline-block;        
      }

      
  }
  .overlay {
      position: fixed;
      z-index:5200;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      pointer-events: none;
      opacity: 1;
      transition: 200ms ease-in-out;
  }
  .buttons_container{
      display: flex;
      flex-direction: row-reverse !important;
      padding-top: 20px;
  }

  .delete_btn{
    font-size: 18px;
    width: 210px;
    margin: 0 !important;
    padding-top: 2px;
    background-color: #A11324 !important;
    .img{
        transform: translateY(-3px);
        padding-right: 10px;
        filter: brightness(100);
    }
}

  .cancel_btn{
      font-weight: bold;
      padding-right: 25px;
  }
}
