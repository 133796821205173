@import 'bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: myYantramanav;
  src: url(./fonts/Yantramanav/Yantramanav-Medium.ttf);
}

@font-face {
  font-family: myYantramanav;
  src: url(./fonts/Yantramanav/Yantramanav-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: myYantramanav;
  src: url(./fonts/Yantramanav/Yantramanav-Medium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: myYantramanav;
  src: url(./fonts/Yantramanav/Yantramanav-Regular.ttf);
  font-weight: lighter;
}


@font-face {
  font-family: myRaleway;
  src: url(./fonts/Raleway/Raleway-Medium.ttf);
}

@font-face {
  font-family: myRaleway;
  src: url(./fonts/Raleway/Raleway-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: myRaleway;
  src: url(./fonts/Raleway/Raleway-Medium.ttf);
  font-weight: normal;
}

@font-face {
  font-family: myRaleway;
  src: url(./fonts/Raleway/Raleway-Light.ttf);
  font-weight: lighter;
}


.App {
  text-align: center;
  font-family: myRaleway, myYantramanav;

  .container-fluid{
    padding: 0;
  }


  &-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }

  &-header {
    background-color: #222;
    padding: 20px;
    color: white;
  }

  &-title {
    font-size: 1.5em;
  }

  &-intro {
    font-size: large;
  }

  color: #333e6b;

  .pageFirstTitle{
    padding-top: 60px;
    padding-bottom: 30px;
    font-size: 20px;
    letter-spacing: 0.05px;
    font-weight: bold;
  }
  .pageTitle{
    padding-bottom: 30px;
    font-size: 20px;
    letter-spacing: 0.05px;
    font-weight: bold;
  }

  .back_btn{
    border-color: #5AA9c7;
    height: 48px;
    color: #5AA9c7;
    font-weight: bold;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .back_btn:hover{
      text-decoration: none;
      color: #2E8EB3;
      border-color: #2E8EB3;
      img {
        filter: brightness(0.8);
      }
  }
  

  .next_btn{
      background-color: #5AA9c7;
      height: 48px;
      color: #fff;
      font-weight: bold;
      border-radius: 10px;
      border-width: 0px;
      border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
  }
  .next_btn:hover{
      text-decoration: none;
      background-color: #2E8EB3;
  }
  

  .disabled{
    .back_btn{
      text-decoration: none !important;
      color: #D0D3D4 !important;
      border-color: #D0D3D4 !important;
      pointer-events: none !important;
    }
    .next_btn{
      text-decoration: none !important;
      background-color: #D0D3D4 !important;
      pointer-events: none !important;
      border: none !important;
    }
  }

  .yantramanav{
    font-family: myYantramanav;
  }

  .raleway{
    font-family: myRaleway;
  }

}
