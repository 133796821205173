.wizard{
  background-color: #fff;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  .centered_form{
    transform: translateX(50%) translateX(- 92px);
    padding-bottom: 10px;
    display: inline-block;
    text-align: left;

    .form_entry{
      .tooltip-properties{   
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          color: #5AA9C7;
          font-family: myYantramanav;
          
      }
    }
  }

  .clean_space{
    height: 10px;
  }

  .next_btn{
    min-width: 160px;
    max-width: 180px;
    font-size: 15px;
  }

  .back_btn{
    min-width: 160px;
    max-width: 180px;
    font-size: 15px;
  }

  overflow-x: hidden;

  .dropdown_form_entry{
    text-align: center;
    margin-left: 6px;
    .label{

      .input_field{
        width: 171px !important;
      }

      .arrow_bg{
        opacity: 0;
      }

      .tooltip-properties-dropdown{
        position: absolute ;
        z-index: 10 ;
        transform: translateX(200px) translateY(10px) !important;

        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        color: #5AA9C7;
        font-family: myYantramanav;
    }
    }
  }
  

  #dropdown_periodsrotate_id{
    .label{
      
      .tooltip-properties-dropdown{
          position: absolute ;
          z-index: 10 ;
          transform: translateX(200px) translateY(10px) !important;
          
      }
    }
  }

  


  
}