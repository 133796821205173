.timeblock{
    
    padding: 0px;
    overflow: hidden;
    position: relative;
    width: 300px;
    
    .timeblock-box {
       margin-left:auto; 
       margin-right:0;
       width: 280px;
       height: 110px;
       @media print {
         height: 61px;
       }
      }     

    .align-period-time{
        text-align: left;
        margin: 10px;
    }
    .align-periodduration{
        text-align: right;
      
    }
  
    .timeblock-column-periodname{
      padding: 10px 0 0 10px;
      margin: 0 0 0 0 ;
      position: relative;
      font-family: myYantramanav;
      font-size: 16px;
      font-weight:bold !important;
      text-align: left;
    }
    .timeblock-column-instructional{
      padding: 0 0 0 10px ;
      margin: 0 0 0 0 ;
      position: relative;
      text-align: left;
      font-family: myRaleway;
      font-weight: normal !important;
      font-style: italic !important;
      font-size: 12px;
    }
    .timeblock-column-text{
      position: absolute;
      left: 50%;
      top: 35%;
      font-family: myRaleway;
      font-weight:normal !important;
      font-style: italic !important;
  
    }
    .timeblock-row{
      width: inherit;
      height: inherit;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
    }

    .timeblock-column{
      width: inherit;
      height: inherit;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
    }
}
