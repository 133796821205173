.lunchpositionselector{
    margin-right: 10px;
    
    .dropdown_form_entry{
        margin-bottom: 10px;
        label{
            
            .input_field{
                font-size: 12px;
                width: 80px !important;
                height: 42px;
            }
            .arrow_bg{
                opacity: 0;
            }
        }
    }

    .overview{
        width: 80px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-items: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;

        font-size: 10px;
        font-family: myYantramanav;
        .period{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #CBD0E3;
            color: #333E6B;
            width: 46px;
            height: 30px;
            margin-bottom: 1px;
        }
        .lunch{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #333E6B;
            color: #CBD0E3;
            width: 46px;
            height: 15px;
            margin-bottom: 1px;
        }
    }

}
