.active{
    opacity: 1 !important;
    pointer-events: all !important;
}

.exportform{
    font-family: myYantramanav;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0 !important;
    

    
    .dropdown_form_entry{
        text-align: start;
        padding-bottom: 22px;
        

        label{
            

            .input_field{
                
            }
        }
        

    }

    .form_entry{
        label{
            font-weight: bold !important;

        }
    }


    .overlay {
        position: fixed;
        z-index:5200;
        top: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
        opacity: 1;
        transition: 200ms ease-in-out;
    }

    .form_window{
        position: absolute;
        overflow-y: auto;
        margin-top: 5vh;

        height: 85vh !important;
        background-color: #fff;
        z-index: 5300;
        padding: 25px 6%;
        border-radius: 15px;
        transform: translateX(15vw);
        width: 70vw;
        .input_field{
            width: 200px;
            
        }
  


        .centered_form{
            align-items: center;
            justify-content: center;
            display: inline-block;
        }
    }

    .title{
        font-size: 24px;
        color: #333E6B;
        font-weight: bold;
    }

    .subtitle{
        color: #5BAAC8;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 35px;
    }

    .buttons_container{
        display: flex;
        flex-direction: row-reverse !important;
        width: 100%;
        padding-right: 70px;
    }

    .send_btn{
        width: 100px;
        margin: 0 !important;
        
    }

    .cancel_btn{
        font-weight: bold;
        padding-right: 25px;
    }

    .disabled{
        text-decoration: none;
        background-color: #D0D3D4 !important;
        pointer-events: none;
        
    }
}
