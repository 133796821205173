.editabledaytype{
    

    :focus {
        outline: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
       }
    .timeblocks{
        margin-bottom: 35px;
    }

    .button_clickable_text_b{
        font-size: 15px;
        margin-bottom: 50px !important;
    }

    .dropBox{
        background-color: black;
        width: 200px;
        height: 200px;
    }

    .timeblock_to_drag{
        z-index: 50;
        position: fixed;
        opacity: 0 !important;
        pointer-events: none !important;
        transition: opacity 0.2s ease-in-out !important;
        top:0px;
        cursor: grabbing;
    }

    .grabbing{
        background-color: black;
        display: none;
    }

}
