.designer{
    position: relative;
    user-select: none;
    opacity: .99;
    
    .thankyou_window{ // Overriding borde-style to none in thankyou modal.
        .window{
            .buttons_container{
                .back_btn{
                    border-style: none;
                }
            }
        }
    }
    

    .designercontainer{
        width: 94vw;
        margin: 0;
        margin-left: 3vw;
        margin-right: 3vw;
        padding-top: 106px;
    }

    .header{
        background-color: #F1F1F1;
        box-shadow: 0px 2px 4px #ddd;
    }

    .button_type_a{
        background-color: #5BAAC8;
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        border-width: 1px;
        border-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .button_clickable_text_a{
        font-size: 18px;
        color: #9B9B9B;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    
    .button_clickable_text_b{
        font-style: normal;
        font-weight: bold;
        color: #65AFCA;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        font-family: myYantramanav;
    }

    .row{
        margin: 0;
        
    }

    .centered_form{
        transform: translateX(50%) translateX(- 92px);
        padding-bottom: 10px;
    }

    .dropdown_disabled{
        pointer-events: none;


        .functionalbutton{
            color: #999 !important;
        }

        
        .dropdown_form_entry{
            .label{
                .input_field{
                    color: #999 !important;

                }
            }
            .label:after{
                border-color: #999 transparent transparent transparent !important;
            }
        }
    }

    .toast-properties{
        font-size: 16px;
        text-align: justify;
        text-justify: inter-word;
        font-family: myYantramanav;
      }
}
