.schedulingconstraintsform{

   padding: 0 0 0 0;
   margin: 0 0 0 0;
   display: flex;
   flex-direction: row;

   .side {
        display:inline-block;
        width: 100px !important;
    }
}