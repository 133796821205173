
.scheduletemplatescreen{
  background-color: rgb(255, 255, 255);
  text-align: center;

  .title{
    padding: 15px !important;
    margin: 0 0 0 0 !important;
  }

  .subtitle{
    font-size: 18px;
    color: #75B7D0;
    padding: 0px;
    margin:  0 0 0 0;
    font-weight: bold;
  }

  .box {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;;
    align-items: center;
  }

  .infocontainer{
    padding: 0 0 0 0;
    margin-top: 45px;
  }

  .infopopup-scheduletemplate{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }

}

