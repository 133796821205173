
.starttimeeditor{
    height: 0px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    overflow: visible;
    color: #333d6b !important;
  
    .timetag-line{
      display: block;
      height: 1px;
      border: 0;  
      border-top: 1px solid #000000;
      padding: 0;
      width: 310px;
      position: relative;
      z-index: 1;
    }

    .time{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transform: translateY(-19px) translateX(-92px);
      background-color: #fff;
      .am{
        padding-left: 3px;
      }
      .hour_position_start_time{
        
        z-index: 1;
        padding: 0;
        margin: 0;
        width: fit-content !important;
      }
      .hour-font{
        font-family: myYantramanav;
        font-weight: normal !important;
        font-size: 16px;
        color: #333e6b !important;
        padding: 0;
        margin: 0;
      }
  
      .form_entry{
          padding: 0;
          margin: 0;
          .input_field{
            text-align: right;
            width: 85px;
            height: 34px;
            font-size: 14px;
            font-weight: normal;
            padding: 0;
            padding-right: 5px;
           

            margin: 0;
          }
      
      }
    }
    
    
  }
  