.weeklyviewschedule{
    
    padding: 5px;
    overflow: hidden;
    position: relative;

    @media print {
      overflow: visible;
      transform: scale(0.58) translateY(-30%) translateX(-35%);
  }
   
  .weeklyviewschedule-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;  
    position: relative;  
    margin-top: 1%;
    margin-left: 70px;
    @media print {
      overflow: visible;
      margin-left: 0px;
    }

  }
  .weeklyviewschedule-element{
      position: relative;
      padding-top: 15px;
      padding-bottom: 30px; 
      margin-left: 4px;
                
  }
  .weeklyviewschedule-element + .weeklyviewschedule-element {
    margin-left: 2px;
  }
   
  .starttimehours{
      width: 90px;
      white-space: pre-wrap;
      font-family: myYantramanav;
      font-weight: normal !important;
      font-size: 16px;
  }

  .endtimehours{
    width: 90px;
    white-space: pre-wrap;
    font-family: myYantramanav;
    font-weight: normal !important;
    font-size: 16px;
  }
  
}

