.weeklytimeblock{
    
    padding: 2px;
    overflow: hidden;
    position: relative;
    width: 150px;
    margin: 0 0 0 0;
    flex-grow: 1;
    
    .weeklytimeblock-box {
       margin-left:auto; 
       margin-right:0;
       width: 150px;
       height: 70px;
     
      }
      
      .weeklytimeblock-classicrose {
        background: #FBD7E7;
      }

      .weeklytimeblock-cinderella {
        background: #fedbcc;
      }

      .weeklytimeblock-goldenglow {
        background: #ffe284;
      } 

      .weeklytimeblock-reef {
        background: #c5e897;
      }

      .weeklytimeblock-foam {
        background: #d6efed;
      }

      
  
  
    .weeklytimeblock-column-periodname{
      padding: 10px 0 0 10px;
      margin: 0 0 0 0 ;
      position: relative;
      font-family: myYantramanav;
      font-size: 16px;
      font-weight:bold !important;
      text-align: left;
    }
    
    .weeklytimeblock-column-text{
      position: absolute;
      left: 40%;
      top: 40%;
      font-family: myRaleway;
      font-weight:normal !important;
      font-style: italic !important;
  
    }
    .weeklytimeblock-row{
      width: inherit;
      height: inherit;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
    }

    .weeklytimeblock-column{
      width: inherit;
      height: inherit;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
    }
}
