.designertabmenu{
    position: fixed;
    padding-top: 75px;
    z-index: 5060;
    
    width: 100%;
    margin: 0;
    background-color: #fff;
    

    .tab_row{
        width: auto;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        max-height: 36px;
        width: fit-content;
        text-align: left;
    }

    .row{
        margin-left: 3% !important;
        margin-right: 3% !important;
        border-style: none none solid none;
        border-width: 1px;
        border-color: #333E6B;
        border-radius: 0;
        display: flex;
        max-height: 36px;
        width: 94%;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .tab_button{
        background-color: #fff;
        border-radius: 0;
        border-width: 5px;
        border-style: none none none none;
        border-color: #333E6B;
        color: #333E6B;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 30px;
        padding-bottom: 15px;
        margin: 0;
        text-decoration: none !important;

        
    }

    :focus {
        outline: 0;
    }
    
    .add_button{
        border-style: none none none none;
        color: #5aa9c7;
        font-size: 16px;
        padding: 0;
        padding-top: 3px;
        margin: 0;
        font-weight: bold;
        width: 160px !important;
        min-width: 160px !important;
        padding-left: 10px;
        cursor: pointer;
        
    }
    .add_button:hover{
        
        color:#2e8eb3 !important;
    }
    .disable{  
        border-style: none none none none;
        font-size: 16px;
        padding: 0;
        padding-top: 3px;
        margin: 0;
        font-weight: bold;
        color:#d0d4d0 !important;  
        width: 160px !important;
        min-width: 160px !important;
        padding-left: 10px; 

        .add_button:hover{
            color:#d0d4d0 !important;
        }
    }

    .tab_button_active{
        border-style: none none solid none !important;
    }

    .disabled_on_saving{
        color: #aaa !important;
        pointer-events: none;
    }
}
