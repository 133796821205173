.dailyviewstack{
    
    padding: 5px;
    overflow: hidden;
    position: relative;
    padding-left: 70px;
    padding-top: 50px;
    width: 100%;
    padding-bottom: 10px;

    .dailyviewstack-size{
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .dailyviewscroll-container {
      margin-top: 1%;
      margin-left: 0;
      margin-right: 0;
      display: flex;        
      overflow-x: scroll;
      overflow-y: hidden;      

  }
  .dailyviewscroll-element{
      position: relative;
      flex-grow: 1;
      margin-left: 200px;
      padding-top: 15px;
      padding-bottom: 30px;
  
              
  }
  .dailyviewscroll-element + .dailyviewscroll-element {
      margin-left: 10%;
  }
   
}
