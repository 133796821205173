.periodmultiselector{
    .periods_display{
        position: absolute;
        display: block;
        z-index: 200;
        width: 210px;
        background-color: #fff !important;
        border-radius: 5px;
        padding: 15px;
        margin-top: 3px;
        font-family: myYantramanav;
        box-shadow: 0px 2px 4px #000;
        max-height: 120px;
        opacity: 0;
        pointer-events: none;
        
        .periods_container{
            overflow-y: auto;
            max-height: 90px;
            .period{
                padding-left: 15px;
                transform: translateY(-3px);
            }
        }   
    }

    .select_periods_btn{
        background-color: #fff;
        border-color: #E7E7E7;
        color: #E7E7E7;
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        width: 202px;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
       
    }

    .select_periods_btn:after{
        position: absolute;
        content: "";
        transform: translateX(165px) translateY(4px);
        border: 6px solid transparent;
        border-color: #75B7D0 transparent transparent transparent;
        z-index: 0;
        pointer-events: none;
    }


    .select_periods_btn_disabled{
        background-color: #fff;
        border: #E7E7E7;
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        color: #E7E7E7 !important;
        width: 202px;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        pointer-events: none;
    }
    .select_periods_btn_disabled:after{
        position: absolute;
        content: "";
        transform: translateX(165px) translateY(4px);
        border: 6px solid transparent;
        border-color: #E7E7E7 transparent transparent transparent;
        z-index: 0;
        pointer-events: none;
    }

    
}
