.functionalbutton{
    width: fit-content;
    outline: none;
    .img{
        padding-right: 2px;
    }
    
}

.functionalbutton:hover{
    cursor: pointer;
}

.tooltip-properties-functionalbtn{
    position: absolute;
    z-index: 10;
    transform: translateX(200px) translateY(30px) !important;
}