.switchformfield{
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    .switch_text{
        font-family: myYantramanav;
        font-style: normal;
        font-size: 12px;
        font-weight: bold;
        width: 210px;
    }
    .switch_image{
        position: absolute;
        transform: translateX(220px) translateY(-30px);
        
    }
    .img_inactive{
        opacity: 0;
    }
}
