.positioned {
    opacity: 1 !important;
}

.editabletimeblock{
    position: relative;
    margin-left: 105px;
    font-family: myYantramanav;
    transition: opacity 0.5s ease-in-out;
    opacity: 0.5;
    color: #333e6b !important;

    .row{
        margin: 0;
    }

    .drag_btn{
        position: absolute;
        width: 8px;
        height: 40px;
        z-index: 1;
        transform: translateY(24px) translateX(4px);
        cursor: grab;
    }

    .lunch_timeblock{
        .lunch{
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
        }
    
        .info{
            font-size: 12px;
            width: fit-content;
            margin: 0;
            padding: 0;
            font-family: myRaleway;
            font-style: normal;
            color: #5AA9C7;
            font-weight: bold;
            transform: translateY(3px);
            padding-left: 10px;
            pointer-events: all;
            cursor: pointer;
        }
    }
    
   

    .timetag{
        position: absolute;
        transform: translateX(-30px);
        z-index: 1;
        
        .timetag-line{
            width: 280px !important;
        }
    }

    .end_time_tag{
        .timetag{
            .timetag-line{
                border-style: dashed;
            }
        }
    }

    .timeblock{
        display: flex;
        align-items: center;
        width: fit-content;
        overflow: visible !important;

        
        
        .timeblock_body{
            
            width: 250px;
            text-align: left;
            padding-left: 35px;
            padding-top: 15px;
            height: 85px;
            .delete_btn{
                z-index: 5;
                position: absolute;
                transform: translateX(185px) translateY(-5px);
            }
            .timeblock_name{
                .form_entry{
                    margin: 0;
                    padding: 0;
                }
                .input_field{
                                     
                    width: 135px;
                    height: 30px;
                    font-size: 14px;
                    font-weight: normal;
                    border: none;
                }

                .input_field{
                    pointer-events: none;
                }
                .dropdown_btn{
                    position: absolute;
                    width: 155px;
                    height: 30px;
                    cursor: pointer;
                    z-index: 40;
                }
                .form_entry:after{
                    position: absolute;
                    content: "";
                    transform: translateX(-25px) translateY(15px);
                    border: 6px solid transparent;
                    border-color: #75B7D0 transparent transparent transparent;
                    z-index: 41;
                    pointer-events: none;
                }
            }
            .is_instructional{
                padding-top: 10px;
                padding-bottom: 5px;
                .text{
                    padding-left: 5px;
                    transform: translateY(-2px);
                    margin: 0;
                }
            }
        }
        
    }

    .passing_time{
        display: flex;
        height: 30px;
        .passing_time_body{
            width: 250px;
            text-align: left;
            padding-left: 35px;
            padding-top: 6px;
            padding-bottom: 5px;
            font-size: 12px;
            font-style: italic;
            font-weight: lighter;
            font-family: myRaleway;
            margin: 0;
            height: 30px;
        }
    }

    .duration{
        display: flex;
        align-items: center !important;
        .form_entry{
            margin: 0;
            padding: 0;
            padding-right: 5px;
        }
        .input_field{
            text-align: end;  
            width: 30px;
            height: 30px;
            font-size: 14px;
            font-weight: normal;

        }

        .min_text{
            padding: 0;
            margin: 0;
            height: fit-content;
            padding-top: 3px;
        }

        .error_msg{
            padding-top: 3px;
            padding-left: 2px;
            width: 230px;
        }

    }
    .lock{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .lock_btn {
            width: 30px;
            height: 30px;
            z-index: 5;
        }
        .lock_img{
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }
        .img_active{
            opacity: 1 !important;
        }
    }
    

    hr {
        display: block;
        height: 1px;
        width: 10px;
        border: 0;
        border-top: 1px solid #ccc;
        margin: 1em 0;
        padding: 0;
    }

    .lock_img_tooltip{
        position: absolute;
        .lock_img{
            position: relative;
        }
    }
}

