.active{
    opacity: 1 !important;
    pointer-events: all !important;
}

.thankyou_window{
    font-family: myYantramanav;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    padding-top: 35px;

    .overlay {
        position: fixed;
        z-index:5200;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        pointer-events: none;
        opacity: 1;
        transition: 200ms ease-in-out;
    }
    .window{
        position: absolute;
        background-color: #fff;
        z-index: 5300;
        width: 60vw;
        border-radius: 15px;
        transform: translateX(20vw);
        justify-content: center !important;
        padding-top: 20px;
        padding-bottom: 30px;
        padding-left: 80px;
        padding-right: 80px;
    }

    .title{
        font-size: 24px;
        color: #333E6B;
        font-weight: bold;
       
    }

    .subtitle{  
        color: #333E6B;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
        width: 100%;
        border-bottom: 1px solid #979797;
        
    }

    .buttons_container{
        transform: translateY(10%);
        
        .back_btn{
            margin-bottom: 30px !important;
        }
    }
    
    

    

    .startnewschedulebtn-properties{
        font-size: 18px !important;
        display: flex !important;
        align-items: center !important;
        padding:0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 50px !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        max-width: 1000px !important;
    }

    .otherappbutton-properties{
        font-size: 18px !important;
        display: flex !important;
        align-items: center !important;
        padding: 20px 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 50px !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        max-width: 1000px !important;
        margin-bottom: 15px !important;
       
    }

    .close_btn{
        align-self: right !important;
        margin-right: 0px !important;
        min-width: 90px !important;
        max-width: 90px !important;
        transform: translateX(30px);
        font-size: 18px !important;
    }

    .buttons_container{
        display: flex;
        flex-direction: column !important;
        padding-top: 20px;
        align-items: center;
    }

    .otherToolText{
        padding-top: 15px;
        color: #333E6B;
        font-weight: normal;
        font-size: 18px;
    }

    .tryapptext{
        color: #333E6B;
        font-weight: bold;
        font-size: 18px;
        padding-top: 5px;
    }

    .link-pointer{
        cursor: pointer;
    }
   
}
