.weekdaytypesselector{
    .week_name{
        font-family: myYantramanav;
        color: #9DA2B5;
        font-size: 12px;
        padding-right: 10px;
        padding-bottom: 5px;
    }

    .day_name{
        transform: translateY(-1px);
        font-size: 13px;
        padding-left: 5px;
        padding-right: 10px;
        font-family: myYantramanav;
    }

    .disabled_day_name{
        color: #D1D4D5;
    }
    
    .delete_btn{
        transform: translateY(-5px);
    }
    
    .daycheckboxtooltip{
        position: absolute;
        z-index: 10;
        top: 0%;
        left: 93%;
        padding: 0 0 0 0;
        margin:0 0 0 0;
        
    }

    .eventsnone{
        pointer-events: none;
    }

    .eventsall{
        pointer-events: all;
    }

    .checkbox_container{
        position: relative;
        padding: 0;
        margin: 0;
        
        
        
        .tooltip_div{
            z-index: 50;
            pointer-events: none;
        }
        .checkbox_disabled{
            pointer-events: none;
        }
    }
   

    .row{
        .row{
            
            .tooltipcomponent{
                .tooltip-inner{   
                    z-index:1020;
                    color: #333e5b;
                    font-weight:350;  
                    background-color: rgb(107, 37, 37)  !important;
                    box-shadow: 1px 1.5px 5px #494848 !important;  
                    font-style: italic;
                    border-top:0px solid #a2a2a2;
                    border-bottom:0px solid #a2a2a2;
                    border-right:0px solid #a2a2a2;
                    font-size: 13px;
                    max-width: 500px;
                    max-height: 130px;
                    text-align: left;      
                }
                .tooltip.bs-tooltip-auto[x-placement^=top] .arrow:before, .tooltip.bs-tooltip-top .arrow:before { 
                    border-right-color: #830f0f !important;       
                }
            }
        }
    }
    
}
