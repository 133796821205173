
.tooltipcomponent{

    .tooltip-inner{   
        z-index:1020;
        color: #333e5b;
        font-weight:350;  
        background-color: white  !important;
        box-shadow: 1px 1.5px 5px #494848 !important;  
        font-style: italic;
        border-top:0px solid #a2a2a2;
        border-bottom:0px solid #a2a2a2;
        border-right:0px solid #a2a2a2;
        font-size: 13px;
        max-width: 500px;
        max-height: 130px;
        text-align: left;      
    }
    
    .tooltip.show {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    .tooltip.bs-tooltip-auto[x-placement^=right] .arrow:before, .tooltip.bs-tooltip-right .arrow:before { 
        border-right-color: #f7f7f7 !important;       
    }
    .tooltip.bs-tooltip-auto[x-placement^=top] .arrow:before, .tooltip.bs-tooltip-top .arrow:before { 
        border-top-color: #f7f7f7 !important;       
    }
    .tooltip.bs-tooltip-auto[x-placement^=left] .arrow:before, .tooltip.bs-tooltip-right .arrow:before { 
        border-left-color: #f7f7f7 !important;       
    }
    .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow:before, .tooltip.bs-tooltip-top .arrow:before { 
        border-bottom-color: #f7f7f7 !important;       
    }

    
    
    
}

