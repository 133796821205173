.weeklyviewheader{
   
    
  .weektype-properties{
      font-family: myYantramanav; 
      font-weight: bold;
      padding-left: 1%;
  }


    .header-box{
        width: 95.8vw;
        background-color: #F1F1F1;
        box-shadow: 0px 2px 4px #ddd;
        padding: 10px;
        text-align: center;
        display: flex;
        flex-direction: row;
    }
   

    
   
}
